<template>
  <div class="sp-terms-and-conditions">
    <div v-html="htmlContent"></div>
  </div>
</template>

<script>
  import TermsApi from '@/entities/terms/api/terms.api';
  import normalizeAnchors from '@/entities/terms/helpers/normalize-anchors.helper';

  export default {
    name: 'TermsAndConditionsContainer',
    props: {},

    data() {
      return {
        htmlContent: '',
      };
    },

    computed: {},

    beforeMount() {
      this.loadCookiesTerms();
    },
    methods: {
      async loadCookiesTerms() {
        try {
          this.htmlContent = await TermsApi.getTerms();
          this.$nextTick(() => {
            normalizeAnchors('sp-terms-and-conditions');
          });
        } catch (err) {
          console.log('err1', err);
        }
      },
    },

  };
</script>

<style lang="scss" scoped>
</style>
