import Axios from 'axios';
import getPrid from '@/shared/helpers/get-prid';

export default {
  async getCookies() {
    const { data } = await Axios.get(`https://${location.host}/api/get_doc/cookies/`);

    return data.head.content;
  },
  async getThirdPartyCookies() {
    const { data } = await Axios.get(`https://${location.host}/api/get_doc/third_party_cookies/`);

    return data.head.content;
  },
  async getPrivacy() {
    const { data } = await Axios.get(`https://${location.host}/api/get_doc/privacy/`, {
      headers: {
        prid: getPrid(),
      },
    });
    return data.head?.content;
  },
  async getTerms() {
    const { data } = await Axios.get(`https://${location.host}/api/get_doc/terms/`, {
      headers: {
        prid: getPrid(),
      },
    });

    return data.head?.content;
  },
}
