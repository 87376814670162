// WHY? Because we have some strange <base href="/"/> which fucks every anchor
const normalizeAnchors = (className) => {
  const el = document.getElementsByClassName(className)?.[0];
  const links = el.getElementsByTagName('a');

  const url =  location.href.includes('#') ? location.href.substring(0, location.href.indexOf('#')) : location.href;
  for (let i = 0; i < links.length; i++) {
    const href = links[i].href;
    if (href && href.startsWith(location.origin) && href.includes('#')) {
      const anchor = href.substring(href.indexOf("#") + 1);
      links[i].href = `${url}#${anchor}`
    }
  }
};

export default normalizeAnchors;