<template>
  <div>
    <terms-and-conditions-container/>
  </div>
</template>

<script>
  import TermsAndConditionsContainer
    from '@/entities/terms/features/terms-and-conditions/terms-and-conditions.container';

  export default {
    name: 'TermsAndConditionsView',
    components: { TermsAndConditionsContainer },
    props: {},

    data() {
      return {};
    },

    computed: {},

    methods: {},

  };
</script>

<style lang="scss" scoped>

</style>
